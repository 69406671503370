<template>
  <div>
    <div class="topimg">
      <img src="../../../assets/masterStation/04_01.png" alt="">
      <div class="topimg_txt">
        <span>ABOUT US</span>
        <h3 :style="'border-image: url('+ require('../../../assets/masterStation/04_04.png')+ ') 16 / 13px / 7px  round;'">关于我们</h3>
        <p>最值得信赖的<b>合作</b>伙伴,为您提供最<b>优质的项目</b>与<b>服务</b></p>
      </div>
    </div>

    <div class="introduce">
      <div class="box">
        <h3 class="title">
          COMPANYINTRODUCTION
          <span>公司介绍</span>
        </h3>
        <div class="introduceBox">
          <div class="introduce_txt">
            <h5>贵州忠义染彩科技开发有限公司</h5>
            <p> 贵州忠义染彩科技开发有限公司(Guizhou 7colorSoftNew Co.,Ltd ,简称染彩科技)是一家以软件开发、互联网方案提供为基础,集系统集成、服务咨询、教育培训及硬件销售为一体的高科技企业!</p>
            <p> 公司多年来对行业应用的了解，以及长期丰富经验的积累,集结具有多年开发大中型软件、经验丰富的IT行业软件开发团队。采用当今主流的，大型通信软 件开发技术,打造出系列行业应用软件。
              包括:染彩云教育软件、医院综合信息管理系统(HIS.LIS、PACS)企业人事管理系统、智能办公系统(OA)策 划及推广服务等!</p>
          </div>
          <img src="../../../assets/masterStation/04_08.png" />
        </div>
      </div>

    </div>

    <div class="Culture">
      <div class="box">
        <h3 class="title">THE ENTERPRISE CULTURE
          <span>企业文化</span>
        </h3>

        <div class="Culture_box">
          <ul>
            <li v-for="(item,index) in list" :key="index">
              <i :class="'iconfont '+item.icon"></i>
              <p>{{item.title}}</p>
              <span>{{item.leble}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="box team">
      <h3 class="title">OUR WORKING ENVIRONMENT
        <span>我们的团队 </span>
      </h3>
      <div class="team_list">
        <div class="teamItem" v-for="(item,index) in teamlist" :key="index">
          <img :src="item.img" alt="">
          <p>{{item.txt}}</p>
        </div>
      </div>
    </div>

    <div class="box contact">
      <h3 class="title"> CONTACT US <span>联系我们</span> </h3>
      <div class="img">
        <img src="../../../assets/masterStation/04_16.png" alt="">
      </div>
      <div class="contactBox">
        <div class="logo">
          <img src="../../../assets/masterStation/logo.png" alt="">
          <p>柒彩科技</p>
        </div>
        <div class="contact_txt">
          <p> <i class="iconfont icon-dizhi"></i> 地址:&ensp; 贵阳市云岩区中天未来方舟D7据德郡A栋501 </p>
          <p> <i class="iconfont icon-dianhua"></i> 电话:&ensp; 085185611235 </p>
          <p> <i class="iconfont icon-youxiang"></i> 邮箱:&ensp; sevencolor@163.com </p>
          <p> <i class="iconfont icon-chuanzhen"></i> 传真:&ensp; 085185611235</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      list: [
        {
          icon: 'icon-gongsi',
          title: '公司使命',
          leble: '致力于提供使工作、学习、生活更加方便、丰富的教育事业题库'
        },
        {
          icon: 'icon-fuwulinian',
          title: '服务理念',
          leble: '提高雇员自尊，增强雇员满意度，加快自我发展，提高服务灵活性'
        },
        {
          icon: 'icon-jiazhiguan',
          title: '价值观',
          leble: '在团体活动中实践和享受人生。个人应该参加社群团体，享受友谊与合作，以求实现大家的共同目标'
        }
      ],
      teamlist: [
        { txt: '专业培训', img: require('@/assets/masterStation/图片10.png') },
        { txt: '专业培训', img: require('@/assets/masterStation/图片11.png') },
        { txt: '专业培训', img: require('@/assets/masterStation/图片12.png') },
        { txt: '团队团建', img: require('@/assets/masterStation/图片13.png') }
      ]
    }
  },
  methods: {},
  created () {}
}
</script>
<style lang="less" scoped>
.topimg {
  position: relative;
  img {
    width: 100%;
  }
  .topimg_txt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    span {
      font-size: 28px;
    }
    h3 {
      font-weight: 500;
      font-size: 34px;
      margin: 20px auto 40px;
      width: 180px;
      height: 45px;
      line-height: 45px;
      border: 1px solid transparent;
    }
    p {
      font-size: 38px;
      b {
        font-weight: 500;
      }
    }
  }
}
.title {
  font-size: 24px;
  margin-top: 10px;
  text-align: center;
  color: #313131;
  span {
    display: block;
  }
}

.introduce {
  padding: 100px 0;
  .introduceBox {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    align-items: center;
    img {
      width: 45%;
    }
    .introduce_txt {
      width: 45%;
      color: #606060;
      h5 {
        font-size: 24px;
        margin-bottom: 30px;
      }
      p {
        margin-bottom: 30px;
        line-height: 30px;
        font-size: 18px;
      }
    }
  }
}
.Culture {
  background-color: #f7f7f7;
  padding: 80px 0;
  .Culture_box {
    ul {
      display: flex;
      justify-content: space-around;
      margin-top: 60px;
      li {
        width: 26%;
        text-align: center;
        font-size: 16px;
        line-height: 30px;
        i {
          font-size: 34px;
          margin-bottom: 5px;
        }
        span {
          color: #606060;
        }
      }
    }
  }
}
.team {
  padding: 50px 0;
  .team_list {
    margin-top: 30px;
    display: flex;
    align-items: center;
    .teamItem {
      max-width: 380px;
      margin-right: 45px;
      margin-bottom: 20px;
      position: relative;
      transition: all 0.5s linear;
      &:nth-child(4n) {
        margin-right: 0;
      }
      img {
        width: 100%;
        height: 100%;
      }
      p {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        background-color: rgba(0, 0, 0, 0.3);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
      }
      &:hover {
        p {
          height: 100%;
          transition: all 0.5s ease-in-out;
        }
      }
    }
  }
}
.contact {
  padding: 50px 0 110px;
  .img {
    margin: 20px 0 50px;
    img {
      width: 100%;
    }
  }
  .contactBox {
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      width: 35%;
      display: flex;
      align-items: center;
      img {
        width: 90px;
        height: 90px;
        margin-right: 20px;
      }
      p {
        font-size: 50px;
        color: #4b4b4b;
      }
    }
    .contact_txt {
      width: 35%;
       color: #606060;
       line-height: 35px;
      i{
        font-size: 20px;
        margin-right: 5px;
      }
      p{
        font-size: 18px;
      }
    }
  }
}
</style>
